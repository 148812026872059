import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import Steps from "components/ui/base/Steps";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import FeatureGrid from "components/ui/base/FeatureGrid";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import TestimonialWithImageLeon from "components/ui/extended/TestimonialWithImageLeon";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";

interface CustomerServicePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: CustomerServicePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.solutions.customer-service.seo.title")}
        description={t("pages.solutions.customer-service.seo.description")}
        image={{
          relativePath: "meta/customer-service-software.jpg",
          alt: "Customer support Software to make your customer life easier",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.solutions.customer-service.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.solutions.customer-service.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.solutions.customer-service.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.solutions.customer-service.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeaderWithVideo
        title={t("pages.solutions.customer-service.title")}
        description={t("pages.solutions.customer-service.description")}
        titleMaxWidth={650}
        descriptionMaxWidth={550}
        ctaRegister={{ show: true }}
        video={{
          path: "pages/solutions/customer-service/customer-service-software.mov",
        }}
      />
      <Steps
        steps={[
          {
            title: t("pages.solutions.customer-service.steps.1.title"),
            description: t(
              "pages.solutions.customer-service.steps.1.paragraph"
            ),
            image: {
              relativePath: "icons/live-chat.png",
              alt: "Live Chat icon",
            },
          },
          {
            title: t("pages.solutions.customer-service.steps.2.title"),
            description: t(
              "pages.solutions.customer-service.steps.2.paragraph"
            ),
            image: {
              relativePath: "icons/help-center-software.png",
              alt: "Help Center Icon",
            },
          },
          {
            title: t("pages.solutions.customer-service.steps.3.title"),
            description: t(
              "pages.solutions.customer-service.steps.3.paragraph"
            ),
            image: {
              relativePath: "icons/nps.png",
              alt: "NPS Survey icon",
            },
          },
        ]}
      />

      {/*Believe human beats chatbot*/}
      <HorizontalStoriesMenu
        title={t("pages.solutions.customer-service.believeHumansStories.title")}
        paragraph={t(
          "pages.solutions.customer-service.believeHumansStories.paragraph"
        )}
        elements={[
          {
            title: t(
              "pages.solutions.customer-service.believeHumansStories.1.title"
            ),
            description: t(
              "pages.solutions.customer-service.believeHumansStories.1.description"
            ),
            story: {
              relativePath:
                "pages/features/live-chat/live-chat-superpowers.mov",
            },
          },
          {
            title: t(
              "pages.solutions.customer-service.believeHumansStories.2.title"
            ),
            description: t(
              "pages.solutions.customer-service.believeHumansStories.2.description"
            ),
            story: {
              relativePath:
                "pages/features/video-live-chat/video-live-chat.mov",
            },
          },
          {
            title: t(
              "pages.solutions.customer-service.believeHumansStories.3.title"
            ),
            description: t(
              "pages.solutions.customer-service.believeHumansStories.3.description"
            ),
            story: {
              relativePath:
                "pages/features/help-center/live-chat-help-center-autoreply.mov",
            },
          },
          {
            title: t(
              "pages.solutions.customer-service.believeHumansStories.4.title"
            ),
            description: t(
              "pages.solutions.customer-service.believeHumansStories.4.description"
            ),
            story: {
              relativePath:
                "pages/features/live-chat/smart-assignment-rules.mov",
            },
          },
        ]}
      />

      <TestimonialWithImageLeon />

      {/*Help Center self serve*/}
      <HorizontalStoriesMenu
        title={t("pages.solutions.customer-service.helpCustomersStories.title")}
        paragraph={t(
          "pages.solutions.customer-service.helpCustomersStories.paragraph"
        )}
        storiesPosition={"right"}
        elements={[
          {
            title: t(
              "pages.solutions.customer-service.helpCustomersStories.1.title"
            ),
            description: t(
              "pages.solutions.customer-service.helpCustomersStories.1.description"
            ),
            story: {
              relativePath: "pages/features/help-center/help-center-view.mov",
            },
          },
          {
            title: t(
              "pages.solutions.customer-service.helpCustomersStories.2.title"
            ),
            description: t(
              "pages.solutions.customer-service.helpCustomersStories.2.description"
            ),
            story: {
              relativePath:
                "components/customer-service-stories/in-chat-help-center.mov",
            },
          },
          {
            title: t(
              "pages.solutions.customer-service.helpCustomersStories.3.title"
            ),
            description: t(
              "pages.solutions.customer-service.helpCustomersStories.3.description"
            ),
            story: {
              relativePath:
                "pages/features/help-center/live-chat-help-center-autoreply.mov",
            },
          },
        ]}
      />

      {/*Improve Satisfaction*/}
      <HorizontalStoriesMenu
        title={t("pages.solutions.customer-service.leverageDataStories.title")}
        paragraph={t(
          "pages.solutions.customer-service.leverageDataStories.paragraph"
        )}
        elements={[
          {
            title: t(
              "pages.solutions.customer-service.leverageDataStories.1.title"
            ),
            description: t(
              "pages.solutions.customer-service.leverageDataStories.1.description"
            ),
            story: {
              relativePath:
                "pages/features/team-reports/live-chat-reports-new-conversations.mp4",
            },
          },
          {
            title: t(
              "pages.solutions.customer-service.leverageDataStories.2.title"
            ),
            description: t(
              "pages.solutions.customer-service.leverageDataStories.2.description"
            ),
            story: {
              relativePath: "pages/features/nps/net-promoter-score.mov",
            },
          },
          {
            title: t(
              "pages.solutions.customer-service.leverageDataStories.3.title"
            ),
            description: t(
              "pages.solutions.customer-service.leverageDataStories.3.description"
            ),
            story: {
              relativePath: "pages/features/surveys/in-chat-survey.mov",
            },
          },
        ]}
      />

      <FeatureGrid
        title={t("pages.solutions.customer-service.featureGrid.title")}
        sections={[
          {
            title: t(
              "pages.solutions.customer-service.featureGrid.liveChat.title"
            ),
            description: t(
              "pages.solutions.customer-service.featureGrid.liveChat.description"
            ),
            image: {
              relativePath: "icons/live-chat.png",
              alt: "pages.solutions.customer-service.featureGrid.liveChat.title",
            },
            links: [
              {
                text: t(
                  "pages.solutions.customer-service.featureGrid.liveChat.link"
                ),
                path: "/features/live-chat/",
              },
            ],
          },
          {
            title: t(
              "pages.solutions.customer-service.featureGrid.videoLiveChat.title"
            ),
            description: t(
              "pages.solutions.customer-service.featureGrid.videoLiveChat.description"
            ),
            image: {
              relativePath:
                "components/features/customer-service/video-live-chat-feature-icon.png",
              alt: "pages.solutions.customer-service.featureGrid.videoLiveChat.title",
            },
            links: [
              {
                text: t(
                  "pages.solutions.customer-service.featureGrid.videoLiveChat.link"
                ),
                path: "/features/video-live-chat/",
              },
            ],
          },
          {
            title: t(
              "pages.solutions.customer-service.featureGrid.helpCenter.title"
            ),
            description: t(
              "pages.solutions.customer-service.featureGrid.helpCenter.description"
            ),
            image: {
              relativePath:
                "components/features/customer-service/help-center-feature-icon.png",
              alt: "pages.solutions.customer-service.featureGrid.helpCenter.title",
            },
            links: [
              {
                text: t(
                  "pages.solutions.customer-service.featureGrid.helpCenter.link"
                ),
                path: "/features/help-center/",
              },
            ],
          },
          {
            title: t(
              "pages.solutions.customer-service.featureGrid.teamReports.title"
            ),
            description: t(
              "pages.solutions.customer-service.featureGrid.teamReports.description"
            ),
            image: {
              relativePath:
                "components/features/customer-service/team-reports-feature-icon.png",
              alt: "pages.solutions.customer-service.featureGrid.teamReports.title",
            },
            links: [
              {
                text: t(
                  "pages.solutions.customer-service.featureGrid.teamReports.link"
                ),
                path: "/features/team-reports/",
              },
            ],
          },
          {
            title: t(
              "pages.solutions.customer-service.featureGrid.npsSurveys.title"
            ),
            description: t(
              "pages.solutions.customer-service.featureGrid.npsSurveys.description"
            ),
            image: {
              relativePath: "icons/nps.png",
              alt: "pages.solutions.customer-service.featureGrid.npsSurveys.title",
            },
            links: [
              {
                text: t(
                  "pages.solutions.customer-service.featureGrid.npsSurveys.link"
                ),
                path: "/features/net-promoter-score/",
              },
            ],
          },
          {
            title: t(
              "pages.solutions.customer-service.featureGrid.inApp.title"
            ),
            description: t(
              "pages.solutions.customer-service.featureGrid.inApp.description"
            ),
            image: {
              relativePath: "icons/surveys.png",
              alt: "pages.solutions.customer-service.featureGrid.inApp.title",
            },
            links: [
              {
                text: t(
                  "pages.solutions.customer-service.featureGrid.inApp.link"
                ),
                path: "/features/surveys/",
              },
            ],
          },
        ]}
      />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};
export const CustomerServiceQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customer-service-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
